import React from 'react';
import TopNav from '../../components/navbars/TopNav';
import { FiPhoneCall } from 'react-icons/fi';
import { MdOutlineMailOutline } from 'react-icons/md';
import { AiOutlineMessage } from 'react-icons/ai';

import VendorSec from './sections/VenorsSec';

import Footer from './sections/Footer';

export default function Landing() {
  return (
    <div>
      <TopNav />
      <VendorSec />
      <div
        className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-8'
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 20,
          }}
        >
          <FiPhoneCall size={30} />
          <div style={{ marginLeft: 5 }}>
            <div style={{ fontSize: 12 }}>Phone</div>
            <div style={{ fontSize: 12, color: '#DD5471' }}>+08099877993</div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 20,
          }}
        >
          <MdOutlineMailOutline size={30} />
          <div style={{ marginLeft: 5 }}>
            <div style={{ fontSize: 12 }}>Email</div>
            <div style={{ fontSize: 12, color: '#DD5471' }}>
              support@gade.com
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: 80, background: '#f1f2f3' }}></div>
      <Footer />
    </div>
  );
}
