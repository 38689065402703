import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from '../../assets/images/logo.png';
import ylogo from '../../assets/images/ylogo.png';
import { Link } from 'react-router-dom';

const navigation = [
  { name: 'App Features', href: '#', current: false },
  { name: 'Download', href: '#', current: false },
  { name: 'Become a Vendor', href: '#', current: false },
  { name: 'Our Team', href: '#', current: false },
  { name: 'Our Partners', href: '#', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  return (
    <Disclosure as='nav' className='bg-white'>
      {({ open }) => (
        <>
          <div
            className={
              open
                ? 'mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 bg-[#F8A51B]'
                : 'mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'
            }
          >
            <div className='relative flex h-16 items-center justify-between'>
              <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                {/* Mobile menu button*/}
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-[#F8A51B] hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-transparent'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
              <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                <div className='flex flex-shrink-0 items-center'>
                  {open ? (
                    <Link to='/'>
                      <img
                        style={{ width: 62, height: 16 }}
                        className='block h-8 w-auto lg:hidden'
                        src={ylogo}
                        alt='Your Company'
                      />
                    </Link>
                  ) : (
                    <Link to='/'>
                      <img
                        className='block h-8 w-auto lg:hidden'
                        src={logo}
                        alt='Your Company'
                      />
                    </Link>
                  )}
                  <Link to='/'>
                    <img
                      className='hidden h-8 w-auto lg:block'
                      src={logo}
                      alt='Your Company'
                    />
                  </Link>
                </div>
              </div>
              <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                <div className='hidden sm:ml-6 sm:block'>
                  <div className='flex space-x-4'>
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-gray-900 text-white'
                            : 'text-black hover:bg-[#F8A51B] hover:text-white',
                          'rounded-md px-3 py-2 text-medium font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden bg-[#F8A51B] '>
            <div className='space-y-1 px-2 pt-2 pb-3 '>
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as='a'
                  href={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-gray-900 text-white'
                      : 'text-white hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
