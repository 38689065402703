import React from 'react';

export default function CustomButton({
  BTNColor,
  BTNWidth,
  BTNTextColor,
  BTNFontSize,
  BTNFontWeight,
  BTNText,
  onClick,
}) {
  return (
    <div
      onClick={onClick}
      style={{
        padding: '10px 20px',
        background: BTNColor,
        width: BTNWidth,
        color: BTNTextColor,
        borderRadius: 5,
        fontSize: BTNFontSize || '14px',
        fontWeight: BTNFontWeight || '400',
        textAlign: 'center',
      }}
    >
      {BTNText}
    </div>
  );
}
