import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CustomButton from '../../../components/button/Button';
import Carousel from '../../../components/carousels/Carousels';
import Styles from './Sec1.module.css';
import { Link } from 'react-router-dom';
export default function TopSection() {
  return (
    <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-8'>
      <Row>
        <Col sm={12} md={6}>
          <div className={Styles.topText} style={{ maxWidth: 400 }}>
            Welcome to Gade App
          </div>
          <div className={Styles.topSubText}>
            Empirical records reveal that "Babye" better known as Gade are of
            "Maguzawa" origin who had migrated from the Western Sudan to Kano &
            settle at a
          </div>
          <div className='d-none d-lg-block'>
            <Link to='/vendor'>
              <CustomButton
                BTNText='Get Started'
                BTNWidth={250}
                BTNColor='#F8A51B'
                BTNTextColor='#fff'
              />
            </Link>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <Carousel />
          <div
            className='d-sm-none'
            style={{
              textAlign: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginTop: 30,
            }}
          >
            <Link to='/vendor'>
              <CustomButton
                BTNText='Get Started'
                BTNWidth={250}
                BTNColor='#F8A51B'
                BTNTextColor='#fff'
              />
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
}
