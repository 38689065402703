import React, { useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import CustomButton from '../../../components/button/Button';
import Styles from './Sec1.module.css';
import Vendor from '../../../../src/assets/images/Vendor2.png';
import { Link } from 'react-router-dom';
import { HiArrowLongRight } from 'react-icons/hi2';
import TextInput from '../../../components/inputs/TextInputs';
import { AppContext } from '../../../App';
import Loader from '../../../components/loader/Loader';

export default function TopSection() {
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [desc, setDesc] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [telephone, setTelephone] = useState('');
  const [businessname, setBusinessname] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');

  const app = useContext(AppContext);
  const { createVendor } = app;

  const handleSubmit = () => {
    let body = {
      first_name: name,
      last_name: lastname,
      business_name: businessname,
      phone_number: telephone,
      description: desc,
      email: email,
    };

    setName('');
    setLastname('');
    setBusinessname('');
    setTelephone('');
    setDesc('');
    setEmail('');

    createVendor(body, setLoading, setSuccess, setMessage);
  };

  const validation =
    name && businessname && telephone && desc && email && lastname;

  return (
    <div style={{ paddingTop: '4%', paddingBottom: '8%' }}>
      <Loader
        loading={loading}
        setLoading={setLoading}
        message={message}
        success={success}
      />
      <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-8'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 40,
          }}
        >
          <Link to='/'>
            <div
              style={{
                color: '#949090',
                fontSize: 14,
                marginRight: 2,
                fontWeight: '700',
                cursor: 'pointer',
              }}
            >
              Home
            </div>
          </Link>
          <HiArrowLongRight size={10} />
          <div
            style={{
              color: '#000',
              fontSize: 14,
              marginLeft: 2,
              fontWeight: '700',
              cursor: 'pointer',

              width: 200,
            }}
          >
            Become a vendor
          </div>
        </div>
        <Row>
          <Col sm={12} md={6}>
            <div className={Styles.topTextX}>Become a Vendor</div>
            <div className={Styles.topSubTextX}>
              Register to become a partner. Sell more, increase your earnings
              and manage your online business with us. Your journey to
              digitisation starts here.
            </div>
            <div>
              <Row>
                <Col sm={12} md={6}>
                  <div style={{ marginBottom: 20 }}>
                    <TextInput
                      setValue={setName}
                      value={name}
                      placeholder='First Name'
                    />
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <div style={{ marginBottom: 20 }}>
                    <TextInput
                      setValue={setLastname}
                      value={lastname}
                      placeholder='Last Name'
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col sm={12} md={6}>
                  <div style={{ marginBottom: 20 }}>
                    <TextInput
                      setValue={setTelephone}
                      value={telephone}
                      placeholder='Phone Number'
                    />
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <div style={{ marginBottom: 20 }}>
                    <TextInput
                      placeholder='Business or Personal Email'
                      setValue={setEmail}
                      value={email}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <div style={{ marginBottom: 20 }}>
                    <TextInput
                      placeholder='Business Name'
                      setValue={setBusinessname}
                      value={businessname}
                    />
                  </div>
                </Col>

                <Col sm={12} md={6}>
                  <div style={{ marginBottom: 20 }}>
                    <TextInput
                      placeholder='Describe your business'
                      setValue={setDesc}
                      value={desc}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <div
              style={{
                textAlign: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: 30,
                marginBottom: 30,
              }}
            >
              <Link to='/vendor'>
                <CustomButton
                  BTNText='Submit'
                  BTNWidth={200}
                  BTNColor={validation ? '#F8A51B' : '#F8E6E5'}
                  BTNTextColor='#fff'
                  onClick={() => (validation ? handleSubmit() : null)}
                />
              </Link>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <img style={{ borderRadius: 10 }} src={Vendor} />
          </Col>
        </Row>
      </div>
    </div>
  );
}
