import Carousel from 'react-bootstrap/Carousel';

function CarouselFadeExample() {
  return (
    <Carousel fade>
      <Carousel.Item>
        <img
          style={{ height: 300 }}
          className='d-block w-100  '
          src='https://image.cannabis.wiki/news/istock-1197408356-1594026030-1440w.jpeg'
          alt='First slide'
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          style={{ height: 300 }}
          className='d-block w-100 '
          src='https://fox56news.com/wp-content/uploads/sites/24/2023/02/GettyImages-1207976129.jpg?w=2560&h=1440&crop=1'
          alt='Second slide'
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          style={{ height: 300 }}
          className='d-block w-100 '
          src='https://image.cannabis.wiki/news/istock-1197408356-1594026030-1440w.jpeg'
          alt='Third slide'
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselFadeExample;
