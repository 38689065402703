import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import http from './Http_common';
import Error from './Err';

export default () => {
  const { error } = Error();
  const navigate = useNavigate();

  const createVendor = (body, setLoading, setSuccess, setMessage) => {
    setLoading(true);

    http().then((axios) => {
      axios
        .post('/api/register/become-a-vendor', body)
        .then(async (res) => {
          // setMessage(res.data.message);
          setMessage(
            'Thanks for choosing GADE. Our support will review your submission and revert with account credentials'
          );
          setSuccess(true);

          return;
        })
        .catch((e) => {
          error(e, setMessage, setSuccess);
        });
    });
  };

  return {
    createVendor,
  };
};
