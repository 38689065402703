import { useNavigate } from 'react-router-dom';

export default () => {
  const navigate = useNavigate();
  const error = (e, setMessage, setSuccess) => {
    if (e?.toJSON()?.message === 'Network Error') {
      setMessage('Session timeout, please login again');
      setSuccess(false);
    }
    const resMessage =
      (e.response && e.response.data && e.response.data.message) ||
      e.message ||
      e.toString();
    if (typeof resMessage == 'string') {
      setMessage(resMessage);
      setSuccess(false);
    } else {
      Object.values(resMessage).map((msg) => {
        msg.map((item, index) => {
          setMessage(item);
          setSuccess(false);
        });
      });
    }
    if (e.response.status == 401 || e.response == 405) {
      setMessage('Session timeout, please login again');
      setSuccess(false);
      setTimeout(() => {
        setSuccess(false);
        navigate('/');
      }, 2500);
    }
    if (e.response.status == 500) {
      setMessage('Something went wrong');
      setSuccess(false);
    }

    setSuccess(false);
  };

  return {
    error,
  };
};
