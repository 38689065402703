import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CustomButton from '../../../components/button/Button';
import Styles from './Sec1.module.css';
import Vendor from '../../../assets/images/Vendor.png';
import { Link } from 'react-router-dom';

export default function TopSection() {
  return (
    <div
      style={{ background: '#FFFBF3', paddingTop: '8%', paddingBottom: '8%' }}
    >
      <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-8'>
        <Row>
          <Col sm={12} md={5}>
            <img src={Vendor} />
          </Col>
          <Col sm={12} md={7}>
            <div className={Styles.topTextX}>Becoming a Vendor</div>
            <div className={Styles.topSubTextX}>
              Restaurant Landing Page designed by Sajib Das Supriyo for Twinkle.
              Connect with them on Dribbble; the global community for designers
              and creative professionals. Restaurant Landing Page designed by
              Sajib Das Supriyo for Twinkle. Connect with them on Dribbble; the
              global community for designers and creative professionals.
            </div>
            <div className='d-none d-lg-block'>
              <Link to='/vendor'>
                <CustomButton
                  BTNText='Become a vendor'
                  BTNWidth={250}
                  BTNColor='#F8A51B'
                  BTNTextColor='#fff'
                />
              </Link>
            </div>
            <div
              className='d-sm-none'
              style={{
                textAlign: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: 30,
              }}
            >
              <Link to='/vendor'>
                <CustomButton
                  BTNText='Become a vendor'
                  BTNWidth={250}
                  BTNColor='#F8A51B'
                  BTNTextColor='#fff'
                />
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
