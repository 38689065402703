import React from 'react';
import TopNav from '../../components/navbars/TopNav';
import TopSection from './sections/Sec1';
import AppFeature from './sections/AppFeature';
import AppDownLoadSec from './sections/AppDownloadSec';
import VendorSec from './sections/VenorsSec';
import OurTeam from './sections/OurTeam';
import Testimonia from './sections/Testimonia';
import PartnerRetsurant from './sections/Partners';
import Footer from './sections/Footer';

export default function Landing() {
  return (
    <div>
      <TopNav />
      <TopSection />
      <AppFeature />
      <AppDownLoadSec />
      <VendorSec />
      <OurTeam />
      <Testimonia />
      <PartnerRetsurant />
      <Footer />
    </div>
  );
}
