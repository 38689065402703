import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CustomButton from '../../../components/button/Button';
import Carousel from '../../../components/carousels/Carousels';
import Styles from './Sec1.module.css';
import { MdNetworkWifi3Bar } from 'react-icons/md';

export default function TopSection() {
  return (
    <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-8'>
      <h4 className={Styles.header}>App Features</h4>
      <Row>
        <Col sm={12} md={3} className='mb-12'>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <div
              style={{
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <MdNetworkWifi3Bar size={25} />
            </div>
            <div
              style={{
                fontSize: '14px',
                color: '#6A6767',
                fontWeight: 800,
              }}
            >
              <div>Download</div>
              <div style={{ marginTop: -5 }}>our mobile app</div>
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6A6767',
                    fontWeight: 300,
                    marginTop: 10,
                    maxWidth: '90%',
                  }}
                >
                  Restaurant Landing Page designed by Sajib Das Supriyo for
                  Twinkle. Connect with them on Dribbble; the global community
                  for designers and creative professionals.
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} className='mb-12'>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <div
              style={{
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <MdNetworkWifi3Bar size={25} />
            </div>
            <div
              style={{
                fontSize: '14px',
                color: '#6A6767',
                fontWeight: 800,
              }}
            >
              <div>Download</div>
              <div style={{ marginTop: -5 }}>our mobile app</div>
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6A6767',
                    fontWeight: 300,
                    marginTop: 10,
                    maxWidth: '90%',
                  }}
                >
                  Restaurant Landing Page designed by Sajib Das Supriyo for
                  Twinkle. Connect with them on Dribbble; the global community
                  for designers and creative professionals.
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} className='mb-12'>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <div
              style={{
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <MdNetworkWifi3Bar size={25} />
            </div>
            <div
              style={{
                fontSize: '14px',
                color: '#6A6767',
                fontWeight: 800,
              }}
            >
              <div>Download</div>
              <div style={{ marginTop: -5 }}>our mobile app</div>
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6A6767',
                    fontWeight: 300,
                    marginTop: 10,
                    maxWidth: '90%',
                  }}
                >
                  Restaurant Landing Page designed by Sajib Das Supriyo for
                  Twinkle. Connect with them on Dribbble; the global community
                  for designers and creative professionals.
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} className='mb-12'>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <div
              style={{
                marginBottom: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <MdNetworkWifi3Bar size={25} />
            </div>
            <div
              style={{
                fontSize: '14px',
                color: '#6A6767',
                fontWeight: 800,
              }}
            >
              <div>Download</div>
              <div style={{ marginTop: -5 }}>our mobile app</div>
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6A6767',
                    fontWeight: 300,
                    marginTop: 10,
                    maxWidth: '90%',
                    maxWidth: '90%',
                  }}
                >
                  Restaurant Landing Page designed by Sajib Das Supriyo for
                  Twinkle. Connect with them on Dribbble; the global community
                  for designers and creative professionals.
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
