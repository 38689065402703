import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CustomButton from '../../../components/button/Button';
import Carousel from '../../../components/carousels/Carousels';
import Styles from './Sec1.module.css';
import IosLogo from '../../../assets/images/IosDownload.png';
import AndroidLogo from '../../../assets/images/AndroidDownload.png';
import Phone from '../../../assets/images/Phone.png';

export default function TopSection() {
  return (
    <div
      style={{
        background: '#F8A51B',
        marginTop: '6%',
      }}
    >
      <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 '>
        <Row>
          <Col
            sm={12}
            md={7}
            style={{
              flexDirection: 'column',

              justifyContent: 'center',

              display: 'flex',
            }}
          >
            <div className={Styles.topText2}>Download our mobile app</div>
            <div className={Styles.topSubText2}>
              Empirical evidences & historical records reveal that "Babye"
              better known as "Gade" are of "Maguzawa" origin
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img
                src={IosLogo}
                alt=''
                style={{ width: 130, height: 47, marginRight: 20 }}
              />
              <img
                src={AndroidLogo}
                alt=''
                style={{ width: 130, height: 47 }}
              />
            </div>
          </Col>
          <Col sm={12} md={5}>
            <div className={Styles.phone}>
              <img
                src={Phone}
                alt=''
                className='d-none d-lg-block'
                style={{ width: 460, height: 700 }}
              />

              <div className={Styles.phone2}>
                <img
                  src={Phone}
                  alt=''
                  className='d-lg-none'
                  style={{ width: 350, height: 500 }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
