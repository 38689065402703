import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CustomButton from '../../../components/button/Button';
import Carousel from '../../../components/carousels/Carousels';
import Styles from './Sec1.module.css';
import { MdNetworkWifi3Bar } from 'react-icons/md';
import Team from '../../../../src/assets/images/Team.png';

export default function TopSection() {
  return (
    <div
      style={{
        background: 'rgba(247,247,247,0.8',
        paddingTop: 50,
        marginTop: 100,
      }}
    >
      <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-8'>
        <Row>
          <Col sm={12} md={3}>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  // marginLeft: 20,
                }}
              >
                <div>
                  <div
                    style={{
                      color: '#000',
                      marginTop: 25,
                      fontSize: 36,
                      maxWidth: 200,
                    }}
                  >
                    what they say about us
                  </div>
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#6A6767',
                      fontWeight: 300,
                      marginTop: 10,
                      background: '#D9D9D9',
                      padding: 40,
                      borderRadius: 3,
                    }}
                  >
                    Restaurant Landing Page designed by Sajib Das Supriyo for
                    Twinkle. Connect with them on Dribbble; the global community
                    for designers and creative professionals.
                    <div
                      style={{
                        color: '#000',
                        marginTop: 25,
                        fontSize: 16,
                        fontWeight: 800,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <img
                        src={Team}
                        alt=''
                        style={{ width: 35, height: 35, borderRadius: 50 }}
                      />
                      <div>- Frank Ika</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={3}>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  // marginLeft: 20,
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#6A6767',
                      fontWeight: 300,
                      marginTop: 10,
                      background: '#D9D9D9',
                      padding: 40,
                      borderRadius: 3,
                    }}
                  >
                    Restaurant Landing Page designed by Sajib Das Supriyo for
                    Twinkle. Connect with them on Dribbble; the global community
                    for designers and creative professionals.
                    <div
                      style={{
                        color: '#000',
                        marginTop: 25,
                        fontSize: 16,
                        fontWeight: 800,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <img
                        src={Team}
                        alt=''
                        style={{ width: 35, height: 35, borderRadius: 50 }}
                      />
                      <div>- Frank Ika</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  // marginLeft: 20,
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#6A6767',
                      fontWeight: 300,
                      marginTop: 10,
                      background: '#F8E6E6',
                      padding: 40,
                      borderRadius: 3,
                    }}
                  >
                    Restaurant Landing Page designed by Sajib Das Supriyo for
                    Twinkle. Connect with them on Dribbble; the global community
                    for designers and creative professionals.
                    <div
                      style={{
                        color: '#000',
                        marginTop: 25,
                        fontSize: 16,
                        fontWeight: 800,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <img
                        src={Team}
                        alt=''
                        style={{ width: 35, height: 35, borderRadius: 50 }}
                      />
                      <div>- Frank Ika</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={3}>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  // marginLeft: 20,
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#6A6767',
                      fontWeight: 300,
                      marginTop: 10,
                      background: '#F8E6E6',
                      padding: 40,
                      borderRadius: 3,
                    }}
                  >
                    Restaurant Landing Page designed by Sajib Das Supriyo for
                    Twinkle. Connect with them on Dribbble; the global community
                    for designers and creative professionals.
                    <div
                      style={{
                        color: '#000',
                        marginTop: 25,
                        fontSize: 16,
                        fontWeight: 800,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <img
                        src={Team}
                        alt=''
                        style={{ width: 35, height: 35, borderRadius: 50 }}
                      />
                      <div>- Frank Ika</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  // marginLeft: 20,
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#6A6767',
                      fontWeight: 300,
                      marginTop: 10,
                      background: '#D9D9D9',
                      padding: 40,
                      borderRadius: 3,
                    }}
                  >
                    Restaurant Landing Page designed by Sajib Das Supriyo for
                    Twinkle. Connect with them on Dribbble; the global community
                    for designers and creative professionals.
                    <div
                      style={{
                        color: '#000',
                        marginTop: 25,
                        fontSize: 16,
                        fontWeight: 800,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <img
                        src={Team}
                        alt=''
                        style={{ width: 35, height: 35, borderRadius: 50 }}
                      />
                      <div>- Frank Ika</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={3}>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  fontSize: '14px',
                  color: '#CBD8D7',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  // marginLeft: 20,
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#6A6767',
                      fontWeight: 300,
                      marginTop: 10,
                      background: '#D9D9D9',
                      padding: 40,
                      borderRadius: 3,
                    }}
                  >
                    Restaurant Landing Page designed by Sajib Das Supriyo for
                    Twinkle. Connect with them on Dribbble; the global community
                    for designers and creative professionals.
                    <div
                      style={{
                        color: '#000',
                        marginTop: 25,
                        fontSize: 16,
                        fontWeight: 800,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <img
                        src={Team}
                        alt=''
                        style={{ width: 35, height: 35, borderRadius: 50 }}
                      />
                      <div>- Frank Ika</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  // marginLeft: 20,
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#6A6767',
                      fontWeight: 300,
                      marginTop: 10,
                      background: '#D9D9D9',
                      padding: 40,
                      borderRadius: 3,
                    }}
                  >
                    Restaurant Landing Page designed by Sajib Das Supriyo for
                    Twinkle. Connect with them on Dribbble; the global community
                    for designers and creative professionals.
                    <div
                      style={{
                        color: '#000',
                        marginTop: 25,
                        fontSize: 16,
                        fontWeight: 800,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <img
                        src={Team}
                        alt=''
                        style={{ width: 35, height: 35, borderRadius: 50 }}
                      />
                      <div>- Frank Ika</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
