import React, { useState } from 'react';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

export default function TextInputs({
  setValue,
  value,
  placeholder,
  inputType,
}) {
  const [hide, setHide] = useState(true);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: -20,
        position: 'relative',
      }}
    >
      <input
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        value={value}
        type={inputType == 'password' && hide === true && inputType}
        secU
        style={{
          width: '100%',
          borderBottomWidth: 0.5,
          borderBlockColor: '#737B7D',
          padding: 10,
          paddingLeft: '10px',
          outline: 'none',
          fontSize: '16px',
          fontWeight: '400',
        }}
      />
      {inputType == 'password' && (
        <div
          onClick={() => setHide(!hide)}
          style={{
            position: 'absolute',
            right: 20,
            zIndex: 1,
            cursor: 'pointer',
          }}
        >
          {hide ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
        </div>
      )}
    </div>
  );
}
