import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function Loader({ loading, setLoading, message, success }) {
  return (
    <div>
      <Modal
        show={loading}
        onHide={() => setLoading(!loading)}
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Body>
          {message != '' && (
            <div style={{ color: success ? 'green' : 'red', marginBottom: 5 }}>
              {success ? 'Successful!' : 'Error!'}
            </div>
          )}
          <p style={{ fontSize: 14, color: 'AFADAD' }}>
            {' '}
            {message ? message : 'Sending...'}
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
}
