import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CustomButton from '../../../components/button/Button';
import Carousel from '../../../components/carousels/Carousels';
import Styles from './Sec1.module.css';
import { MdNetworkWifi3Bar } from 'react-icons/md';
import Logo from '../../../../src/assets/images/logoicon.png';

export default function TopSection() {
  return (
    <div
      style={{
        background: '#3B3B3C',
        paddingTop: 10,
        paddingBottom: 10,
        marginTop: '7%',
      }}
    >
      <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-8'>
        <Row>
          <Col xs={6} lg={3}>
            <img src={Logo} alt='' />
            <div
              style={{
                fontSize: '14px',
                color: '#fff',
                fontWeight: 400,
                marginTop: 20,
              }}
            >
              20 Abeokuta street, shooting stars road, Adeogun. Lagos State
            </div>
          </Col>
          <Col xs={6} lg={3}>
            <div
              style={{
                fontSize: '16px',
                color: '#fff',
                fontWeight: 800,
                maxWidth: 100,
                marginBottom: 10,
              }}
            >
              City
            </div>
            <div
              style={{
                fontSize: '14px',
                color: '#fff',
                fontWeight: 400,
                maxWidth: 100,
              }}
            >
              Lagos Abuja Ibadan Port Harcourt{' '}
            </div>
          </Col>
          <Col xs={6} lg={3}>
            <div
              style={{
                fontSize: '16px',
                color: '#fff',
                fontWeight: 800,
                maxWidth: 150,
                marginBottom: 10,
              }}
            >
              City
            </div>
            <div
              style={{
                fontSize: '14px',
                color: '#fff',
                fontWeight: 400,
                maxWidth: 100,
              }}
            >
              Lagos Abuja Ibadan Port Harcourt{' '}
            </div>
          </Col>
          <Col xs={6} lg={3}>
            <div
              style={{
                fontSize: '16px',
                color: '#fff',
                fontWeight: 800,
                maxWidth: 150,
                marginBottom: 10,
              }}
            >
              City
            </div>
            <div
              style={{
                fontSize: '14px',
                color: '#fff',
                fontWeight: 400,
                maxWidth: 150,
              }}
            >
              Burgers in Lagos Pizza in Lagos Nigerian Food in Lagos African
              Food in Lagos
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
