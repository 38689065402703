import React, { createContext } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Landing from './pages/landing_page/Landing';
import ContactUs from './pages/contact_page/Contact';
import Login from './pages/vendors/Login';
import VendorService from '../src/services/vendor.service';

export const AppContext = createContext();

const Root = () => {
  const { createVendor } = VendorService();
  return (
    <AppContext.Provider
      value={{
        createVendor,
      }}
    >
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/vendor' element={<ContactUs />} />
        <Route path='/login' element={<Login />} />
      </Routes>
    </AppContext.Provider>
  );
};

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </div>
  );
}

export default App;
