import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Image from '../../assets/images/pageimage.jpg';
import Logo from '../../assets/images/logo.png';
import CustomButton from '../../components/button/Button';
import TextInput from '../../components/inputs/TextInputs';
import { FiPhoneCall } from 'react-icons/fi';
import { MdOutlineMailOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <div>
      <Row>
        <Col style={{ background: '#FFFBF3' }}>
          <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-2'>
            <img src={Logo} alt='' style={{ width: 80, height: 35 }} />
            <div
              style={{
                // display: 'flex',
                // flexDirection: 'column',
                // justifyContent: 'center',
                // alignItems: 'center',

                margin: '25%',
                background: '#fff',
                padding: '5%',
                borderRadius: 10,
              }}
            >
              <div
                style={{
                  color: '#000',
                  fontSize: 20,
                  fontWeight: 800,
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                Vendor Login
              </div>
              <div
                style={{
                  color: '#6A6767',
                  fontSize: 14,
                  fontWeight: 400,
                  marginTop: 10,
                  textAlign: 'center',
                }}
              >
                Enter your details to get signed in to your account
              </div>

              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <TextInput
                  setValue={setEmail}
                  value={email}
                  placeholder='Enter Email Address'
                />
              </div>
              <div style={{ marginTop: 20, marginBottom: 40 }}>
                <TextInput
                  setValue={setPassword}
                  value={password}
                  placeholder='Enter Password'
                  inputType='password'
                />
              </div>

              <Link to='/vendor'>
                <CustomButton
                  BTNText='Login'
                  BTNWidth='100%'
                  BTNColor='#F8A51B'
                  BTNTextColor='#fff'
                />
              </Link>
              <div
                className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-8'
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: 20,
                  }}
                >
                  <FiPhoneCall size={30} />
                  <div style={{ marginLeft: 5 }}>
                    <div style={{ fontSize: 12 }}>Phone</div>
                    <div style={{ fontSize: 12, color: '#DD5471' }}>
                      +08099877993
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: 20,
                  }}
                >
                  <MdOutlineMailOutline size={30} />
                  <div style={{ marginLeft: 5 }}>
                    <div style={{ fontSize: 12 }}>Email</div>
                    <div style={{ fontSize: 12, color: '#DD5471' }}>
                      support@gade.com
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <img src={Image} alt='' />
        </Col>
      </Row>
    </div>
  );
}
