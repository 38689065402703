import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Styles from './Sec1.module.css';

import PartnerCarousel from '../../../components/carousels/PartnerCarousels';

export default function TopSection() {
  return (
    <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-8'>
      <h4 className={Styles.header} style={{ color: '#000' }}>
        Patnered Restaurant
      </h4>
      <div>
        <PartnerCarousel />
      </div>

      {/* <Row>
        <Col sm={Ptnr1} md={2} className='mb-12'>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <img
              style={{ borderRadius: 8 }}
              src={Ptnr1}
              alt=''
              style={{ width: 80, height: 80 }}
            />
            <div
              style={{
                fontSize: '14px',
                color: '#6A6767',
                fontWeight: 800,

                alignSelf: 'start',
                textAlign: 'left',
                marginLeft: 20,
              }}
            ></div>
          </div>
        </Col>
      </Row> */}
    </div>
  );
}
