import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CustomButton from '../../../components/button/Button';
import Carousel from '../../../components/carousels/Carousels';
import Styles from './Sec1.module.css';
import { MdNetworkWifi3Bar } from 'react-icons/md';
import Team from '../../../../src/assets/images/Team.png';

export default function TopSection() {
  return (
    <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-8'>
      <h4 className={Styles.header}>Meet Our Team</h4>
      <Row>
        <Col sm={12} md={3} className='mb-12'>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <img style={{ borderRadius: 8 }} src={Team} alt='' />
            <div
              style={{
                fontSize: '14px',
                color: '#6A6767',
                fontWeight: 800,

                alignSelf: 'start',
                textAlign: 'left',
                marginLeft: 20,
              }}
            >
              <div>
                <div style={{ color: '#000', marginTop: 25, fontSize: 16 }}>
                  Olumide Dammy
                </div>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6A6767',
                    fontWeight: 300,
                    marginTop: 10,
                    // maxWidth: '90%',
                  }}
                >
                  The global community for designers and creative professionals.
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} className='mb-12'>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <img style={{ borderRadius: 8 }} src={Team} alt='' />
            <div
              style={{
                fontSize: '14px',
                color: '#6A6767',
                fontWeight: 800,

                alignSelf: 'start',
                textAlign: 'left',
                marginLeft: 20,
              }}
            >
              <div>
                <div style={{ color: '#000', marginTop: 25, fontSize: 16 }}>
                  Olumide Dammy
                </div>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6A6767',
                    fontWeight: 300,
                    marginTop: 10,
                    // maxWidth: '90%',
                  }}
                >
                  The global community for designers and creative professionals.
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} className='mb-12'>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <img style={{ borderRadius: 8 }} src={Team} alt='' />
            <div
              style={{
                fontSize: '14px',
                color: '#6A6767',
                fontWeight: 800,

                alignSelf: 'start',
                textAlign: 'left',
                marginLeft: 20,
              }}
            >
              <div>
                <div style={{ color: '#000', marginTop: 25, fontSize: 16 }}>
                  Olumide Dammy
                </div>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6A6767',
                    fontWeight: 300,
                    marginTop: 10,
                    // maxWidth: '90%',
                  }}
                >
                  The global community for designers and creative professionals.
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12} md={3} className='mb-12'>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <img style={{ borderRadius: 8 }} src={Team} alt='' />
            <div
              style={{
                fontSize: '14px',
                color: '#6A6767',
                fontWeight: 800,

                alignSelf: 'start',
                textAlign: 'left',
                marginLeft: 20,
              }}
            >
              <div>
                <div style={{ color: '#000', marginTop: 25, fontSize: 16 }}>
                  Olumide Dammy
                </div>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6A6767',
                    fontWeight: 300,
                    marginTop: 10,
                    // maxWidth: '90%',
                  }}
                >
                  The global community for designers and creative professionals.
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
