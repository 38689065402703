import Carousel from 'react-bootstrap/Carousel';
import Ptnr1 from '../../../src/assets/images/Burger.png';
import Ptnr2 from '../../../src/assets/images/McDonald.png';
import { Row, Col } from 'react-bootstrap';

function CarouselFadeExample() {
  return (
    <Carousel fade>
      <Carousel.Item>
        <Row>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr1}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr1}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr1}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr1}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr1}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr1}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
        </Row>
      </Carousel.Item>
      <Carousel.Item>
        <Row>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr2}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr2}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr2}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr2}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr2}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr1}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
        </Row>
      </Carousel.Item>
      <Carousel.Item>
        <Row>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr2}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr2}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr1}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr1}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr2}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
          <Col xs={4} md={2} className='mb-12'>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{ borderRadius: 8 }}
                src={Ptnr1}
                alt=''
                style={{ width: 80, height: 80 }}
              />
              <div
                style={{
                  fontSize: '14px',
                  color: '#6A6767',
                  fontWeight: 800,

                  alignSelf: 'start',
                  textAlign: 'left',
                  marginLeft: 20,
                }}
              ></div>
            </div>
          </Col>
        </Row>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselFadeExample;
